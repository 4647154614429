// import { Navigate } from 'react-router-dom'
import { RouteItem } from './interface'
import HomePage from '../pages/home'
import AboutPage from '../pages/about'
import NotFoundPage from '../pages/404'
// import TestPage from '../pages/test';
// import D3GraphPage from '../pages/d3-graph';
// import UserPage from '../pages/user';

const routerList: RouteItem[] = [
  {
    path: '/',
    element: <HomePage />,
    meta: {
      title: '极融',
      isNeedAuth: true,
    },
    // element: <Navigate to="/home" />,
  },
  {
    path: '/home',
    element: <HomePage />,
    meta: {
      title: '极融',
      isNeedAuth: true,
    },
    // children: [
    //   {
    //     path: 'test',
    //     element: <TestPage />,
    //     meta: {
    //       title: 'test',
    //     },
    //   },
    //   {
    //     path: 'test/:id',
    //     element: <UserPage />,
    //     meta: {
    //       title: 'test2',
    //     },
    //   },
    //   {
    //     path: 'test/:id/user',
    //     element: <UserPage />,
    //     meta: {
    //       title: 'user',
    //     },
    //   },
    //   {
    //     path: ':type',
    //     element: <D3GraphPage />,
    //     meta: {
    //       title: 'd32',
    //     },
    //   },
    // ],
  },
  // {
  //   path: '/d3',
  //   element: <D3GraphPage />,
  //   meta: {
  //     title: 'd3',
  //   },
  // },
  {
    path: '/about',
    element: <AboutPage />,
    meta: {
      title: 'd3',
    },
  },
  {
    path: '*',
    element: <NotFoundPage />,
    meta: {
      title: '页面走丢了~',
    },
  },
]
export default routerList
