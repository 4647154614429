import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import App from './App.tsx'
import './index.scss';
import RouterLoader from './router';

// const hostname = window.location.hostname
// const isMobile = () => /Mobi|Android|iPhone/i.test(navigator.userAgent)
// if (hostname.includes('test') && isMobile()) {
//   import('vconsole').then(({ default: C }) => {
//     new C()
//   })
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		{/* <App /> */}
		<BrowserRouter>
			<RouterLoader />
		</BrowserRouter>
	</React.StrictMode>
);
