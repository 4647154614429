import { Route, Routes, useLocation } from 'react-router-dom'
import routerList from './config'
import { RouteItem } from './interface'

function renderRoutes(routes: RouteItem[]): JSX.Element[] {
  return routes.map((item, index) => {
    if (item && item.children) {
      return (
        <Route
          path={item.path}
          element={<BeforeEnter item={item} />}
          key={index}
        >
          {renderRoutes(item.children)}
        </Route>
      )
    } else {
      return (
        <Route
          path={item.path}
          element={<BeforeEnter item={item} />}
          key={index}
        />
      )
    }
  })
}

interface BeforeEnterProps {
  item: RouteItem
}

const BeforeEnter = ({ item }: BeforeEnterProps): JSX.Element => {
  // console.log('item', item)
  document.title = item.meta?.title ? item.meta?.title : '\u200E'
  return item.element!
}

const RouterLoader = (): JSX.Element => {
  const { pathname } = useLocation()
  console.log('pathname', pathname)
  // console.log('routerList', routerList)

  return (
    <>
      <Routes>{renderRoutes(routerList)}</Routes>
    </>
  )
}

export default RouterLoader
