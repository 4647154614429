import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import './style.scss'
import { Autoplay, Pagination } from 'swiper/modules'
import bg01 from './img/bg01.png'
import bg02 from './img/bg02.png'
import bg03 from './img/bg03.png'
import bg04 from './img/bg04.png'
import logo from './img/logo.png'
import bg05 from './img/bg05.png'
import bg06 from './img/bg06.png'
import bg07 from './img/bg07.png'
import logo2 from './img/ft-lg.png'

const HomePage = () => {
  return (
    <div className="home-page">
      <header className="page-cont header-logo">
        <div className="logo-wrap">
          <img src={logo} alt="" />
          <div className="text">
            <p>合规持牌分</p>
            <p>期借款平台</p>
          </div>
        </div>
      </header>
      <main>
        <div className="swiper-wrap">
          <Swiper
            slidesPerView={1}
            // spaceBetween={50}
            autoplay={{
              delay: 3000,
            }}
            loop={true}
            pagination={true}
            modules={[Autoplay, Pagination]}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <img src={bg01} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={bg02} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={bg03} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={bg04} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>

        <section className="to-know">
          <img src={bg05} alt="" className="bg05" />
          <p className="about-text">
            大连高新园区中融信小额贷款股份有限公司旗下“极融”产品为用户提供一站式助贷服务，免费测试信用额度，我们提供0元-200000元不等的借款额度，解决日常资金周转问题。
          </p>
        </section>
        <section className="third-wrap">
          <div className="page-cont">
            <h2 className="title">智慧金融方案</h2>
            <ul className="module-wrap">
              <li className="module">
                <div className="module-hd">
                  <img src={bg06} alt="" />
                </div>
                <div className="module-bd">
                  <h4>产品特点</h4>
                  <p>提供0元-200000元不等的额度测试；</p>
                  <p>操作简单，快速填写资料；</p>
                  <p>提供合格的助贷服务，当天放款；</p>
                  <p>还款周期长，还款压力小；</p>
                  <p>与银行、消费金融等机构合作，安全放心；</p>
                </div>
              </li>
              <li className="module">
                <div className="module-hd">
                  <img src={bg07} alt="" />
                </div>
                <div className="module-bd">
                  <h4>关于我们</h4>
                  <p>
                    大连高新园区中融信小额贷款股份有限公司于2011注册成立，拥有稀缺的网络小额贷款牌照、聚合支付能力、大数据风控能力，致力于成为消费金融行业的积极领跑者。
                    旗下“极融”APP提供各类消费金融产品，并为各类开展消费金融服务的企业提供一站式金融基础设施服务，为大流量机构提供合规的金融产品解决方案及流量变现服务，包括金融合规、资金供给、聚合支付、风险控制、产品设计和技术服务等
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </main>
      <footer>
        <div className="page-cont">
          <div className="logo-wrap">
            <img src={logo2} alt="" />
          </div>
          <div className="text-wrap">
            <p>
              <a href="https://beian.miit.gov.cn">
                大连高新园区中融信小额贷款股份有限公司 版权所有
                辽ICP备17010038号-1
              </a>
            </p>
            <p>极融热线：4000627626 服务时间：9：00-21：00</p>
            <p>邮箱：jr-service@jirongfintech.com</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default HomePage
