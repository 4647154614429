import { Link } from 'react-router-dom'
import './style.scss'

export default function NotFoundPage() {
  document.title = '页面走丢了~'
  return (
    <div className="not-found-page">
      <div className="page-cont">
        <h1>404</h1>
        <h2>Page not found.</h2>
        <Link to="/">BACK TO HOMEPAGE</Link>
      </div>
    </div>
  )
}
